import React, { Component } from "react";
import AppContext from "../../appContext";
import { TiDelete } from "react-icons/ti";
import { deleteFile } from "../../Functions/files/deleteFile/delete-file.js";
import "./index.css";
import Swal from "sweetalert2";

class DeleteCard extends Component {
  constructor(props) {
    super();
    this.state = {
      active: false,
      sizeIcon: 35,
    };
    this.deleteDoc = this.deleteDoc.bind(this);
    DeleteCard.contextType = AppContext;
  }

  async deleteDoc() {
    const result = await deleteFile(this.props.pasta, this.props.arquivo);
    if(Boolean(result)){
      this.context.hideLoading()
    Swal.fire({
      html: `Arquivo <b>${this.props.arquivo}</b> removido.`,
      icon: "success",
      title: "Pronto!",
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: "#00518b",
      confirmButtonText: "Ok!",
    }).then(result => {
      if (result.value) {
        this.context.showLoading("Carregando...");
        this.props.getFolders();
      }
      });
    }
  }
  handleClick(e) {
    e.preventDefault();
    this.setState({ active: true });

    setTimeout(() => {
      this.setState({ active: false });
    }, 300);
    Swal.fire({
      html: `O arquivo <b>${this.props.arquivo}</b> será removido da pasta <b>${this.props.pasta}</b>. <br/><br/>Deseja continuar?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        this.context.showLoading()
        this.deleteDoc();
      } else {
        return;
      }
    });
  }

  render() {
    const { active, sizeIcon } = this.state;
    const iconClass = active ? "deleteIcon active" : "deleteIcon";

    return (
      <div className={iconClass}>
        <TiDelete size={sizeIcon} onClick={e => this.handleClick(e)} />
      </div>
    );
  }
}

export default DeleteCard;
