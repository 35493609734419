import React, { Component } from "react";
import ReactLoading from "react-loading";

//Import CSS
import "./otica-management.css";
//Import internal components

//Import external components
import { ExpandMore, Visibility, Create, Delete } from "@material-ui/icons";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Input from "../components/inputs/input";
import CustomButton from "../components/buttons/button";
import { Button } from "react-bootstrap";
import AppContext from "../appContext";
import Swal from "sweetalert2";
import { getMultiContas, createMultiContas, disableMultiContas, updateToken, convertJWT, updateRole } from "../service/multi-contas/multi-contas-service";
import OticaManagementRow from "./otica-management-row";
import { getAccountData } from "../Functions/account-data/get-account-data.js";
//Import image

class OticaManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EssilorEmpresa: [],
      novoCnpj: "",
      email: "",
      listaOtica: [],
      apelido: "",
      oticaLoad: true,
      tipo_cnpj: "",
    };
    OticaManagement.contextType = AppContext;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.desativarMultiOticas = this.desativarMultiOticas.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  async verificaTipoDeUsuario() {
    try {
      this.context.showLoading();
      const ultimoCnpjCadastradoMultiContas = this.state.listaOtica[0].cnpj;
      const parametros = {
        cnpj: ultimoCnpjCadastradoMultiContas,
        email: this.context.userSession.email,
        papel: "Novo Usuário Óptica",
        idUsuario: this.context.user_id,
      };

      var VargetAccountData = await getAccountData(parametros);

      return VargetAccountData;
    } catch (error) {
      this.context.hideLoading();
      return true;
    }
  }
  async criarMultiContas() {
    var tipoContatos = await this.verificaTipoDeUsuario();
    const parametros = {
      cnpj: this.state.novoCnpj,
      email: this.context.userSession.email,
      papel: "Novo Usuário Óptica",
      idUsuario: this.context.user_id,
    };
    var VargetAccountData = await getAccountData(parametros);

    if (VargetAccountData.status != 200) {
      this.context.hideLoading();
      Swal.fire({
        html: "Não encontramos seu CNPJ em nossa base. Por favor, entre em contato com o SAC Essilor pelo telefone 0800 727 2007.",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      return;
    }

    const tipoMap = {
      multiconta: ["REDE", "BALCONISTA", "OTICA", "FORNECEDOR", "CONSUMIDORES", "DISTRIBUIDOR"],
      afiliados: ["AFILIADOS", "EMPREGADOS"],
      laboratorio: ["LAB PARTICIPACAO"],
      labIndependente: ["LABORATORIOS", "LAB ALLIANCE"]
    };
    
    const determinarTipo = (tipo) => {
      if (!tipo || tipoMap.multiconta.some(t => tipo.includes(t))) return "OTICA";
      if (tipoMap.afiliados.some(t => tipo.includes(t))) return "ESSILOR";
      if (tipoMap.laboratorio.some(t => tipo.includes(t))) return "LABORATORIO";
      if (tipoMap.labIndependente.some(t => tipo.includes(t))) return "LAB_INDEPENDENTE";
      return ""; 
    };
    
    let tipoCNPJMulticonta = determinarTipo(VargetAccountData.tipo);
    let tipoUsarioLogado = determinarTipo(tipoContatos.tipo);

    if (
      !tipoCNPJMulticonta ||
      tipoCNPJMulticonta.includes("CLINICAS") ||
      tipoCNPJMulticonta.includes("EMPREGADO") ||
      tipoCNPJMulticonta.includes("MEDICO")
    ) {
      Swal.fire({
        html: "Não foi possível criar um vínculo com essa empresa. Favor entrar em contato com o suporte através da Ferramenta <strong><a href='https://essilorluxottica.requestia.com/' target='_blank'>Requestia (https://essilorluxottica.requestia.com/)<a/><strong/>",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      this.context.hideLoading();
    } else if (tipoCNPJMulticonta.includes("NAO DEFINIDO")) {
      Swal.fire({
        html: "Este CNPJ está com alguma pendência no Referencial Essilor. Para que seja realizado o ajuste, favor entrar em contato com o suporte através da Ferramenta <strong><a href='https://essilorluxottica.requestia.com/' target='_blank'>Requestia (https://essilorluxottica.requestia.com/)<a/><strong/>",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      this.context.hideLoading();
    } else if (tipoCNPJMulticonta != tipoUsarioLogado) {
      this.context.hideLoading();
      Swal.fire({
        html: "Este CNPJ é inválido.",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    } else {
      var cnpj = this.state.novoCnpj
        .replace(/[^0-9]/g, "")
        .replace("/", "")
        .replace("-", "");

      var papel = "";
      if (tipoCNPJMulticonta.includes("LAB PARTICIPACAO")) {
        papel = "Novo Usuário Laboratório";
      } else if (tipoCNPJMulticonta.includes("LAB")) {
        papel = "Novo Usuário Lab Independente";
      } else {
        papel = "Novo Usuário Óptica";
      }
      const body = {
        email: this.context.userSession.email,
        cnpj: cnpj,
        papel: papel,
        idUsuario: this.context.user_id,
      };

      createMultiContas(body).then(response => {
        if (response.status === 201 || response.status === 200) {
          Swal.fire({
            text: "Empresa adicionada com sucesso!",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(result => {
            if (result.value) {
              this.reloadList();
              this.context.hideLoading();
            }
          });
        } else if (response.message.includes("Você já faz parte desta empresa")) {
          Swal.fire({
            html: response.message,
            icon: "warning",
            title: "Aviso!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        } else {
          Swal.fire({
            html: "Não foi possível criar um vínculo com essa empresa. Favor entrar em contato com o suporte através da Ferramenta <strong><a href='https://essilorluxottica.requestia.com/' target='_blank'>Requestia (https://essilorluxottica.requestia.com/).<a/><strong/>",
            icon: "warning",
            title: "Aviso!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        }
      });
      this.context.hideLoading();
    }
  }

  desativarMultiOticas(id) {
    this.context.showLoading();

    disableMultiContas(id).then(response => {
      this.context.hideLoading();
      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          text: "Empresa removida com sucesso!",
          icon: "success",
          title: "Pronto!",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#00518b",
          confirmButtonText: "Ok!",
        }).then(result => {
          if (result.value) {
            this.updateToken(id);
          }
        });
      } else {
        Swal.fire({
          text: response.message,
          icon: "warning",
          title: "Aviso!",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#00518b",
          confirmButtonText: "Ok!",
        }).then(result => {
          if (result.value) {
          }
        });
      }
    });
  }

  handleSubmit() {
    Swal.fire({
      html: "Você está tentando se vincular ao CNPJ <b>" + this.state.novoCnpj + "</b>. <br> Deseja continuar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        this.criarMultiContas();
      }
    });
  }

  updateToken(id) {
    var contaAtual = this.state.listaOtica.filter((item, index) => {
      return item.id === id;
    });

    if (contaAtual[0].cnpj === this.context.userSession.conta) {
      var contaPrincipal = this.state.listaOtica.filter((item, index) => {
        return item.principal === true;
      });

      var session_token = localStorage.getItem("session_token");

      this.context.showLoading();
      try {
        updateToken(contaPrincipal[0].cnpj, session_token).then(response => {
          if (response.status === 201 || response.status === 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("session_token");
            localStorage.removeItem("grant_token");

            localStorage.setItem("token", response.access_token);
            localStorage.setItem("session_token", response.session_token);
            localStorage.setItem("grant_token", response.grant_token);
            const cnpj = convertJWT(response.access_token).conta;

            window.location.reload();
            this.context.hideLoading();
          } else {
            this.context.hideLoading();
            Swal.fire({
              text: response.message,
              icon: "error",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        });
      } catch (error) {
      } finally {
        this.context.hideLoading();
      }
    } else {
      this.reloadList();
    }
  }

  componentDidMount() {
    this.reloadList();
  }

  async reloadList() {
    const parametros = {
      cnpj: this.context.userSession.conta,
      email: this.context.userSession.email,
      papel: "Novo Usuário Óptica",
      idUsuario: this.context.user_id,
    };

    var VargetAccountData = await getAccountData(parametros);
    this.setState({ EssilorEmpresa: VargetAccountData });
    this.setState({ listaOtica: [], oticaLoad: true });
    var listaOtica = [];
    this.context.showLoading();
    getMultiContas(this.context.userSession.conta).then(response => {
      /**
       * função sem HideLoading por conta do codigo aguardar o menu lateral ser atualizando, assim fechando o loading no componente otica-menu
       */
      if (response.items) {
        response.items.map((item, index) => {
          var papel = item.papel.nome;

          if (response.items.length === 1) {
            papel = this.context.userSession.papel;
          }

          var otica = {
            id: item.Id,
            nome: item.conta.nome,
            cnpj: item.conta.cnpj,
            perfil: papel,
            apelido: item.conta.apelido,
            principal: item.principal,
            ativo: item.principal,
            desativado: !item.ativo,
            semMultiConta: false,
          };

          listaOtica.push(otica);
        });
        this.props.onCallback(this.state.listaOtica);
      }

      if (listaOtica.length > 0 && this.context.userSession.papelCategoria !== "ESSILOR") {
        var auxlist = listaOtica.filter((item, index) => {
          return item.desativado === false;
        });
        this.setState({ listaOtica: auxlist, novoCnpj: "", oticaLoad: false });
      } else if (this.context.userSession.papelCategoria === "ESSILOR") {
        const result = [
          {
            apelido: this.state.EssilorEmpresa.fantasia,
            ativo: true,
            cnpj: this.state.EssilorEmpresa.cnpj,
            desativado: false,
            id: 0,
            nome: this.state.EssilorEmpresa.nome,
            perfil: this.context.userSession.papel,
            principal: true,
            semMultiConta: true,
          },
        ];

        this.setState({ listaOtica: result, oticaLoad: false });
      }
    });
  }

  render() {
    return (
      <>
        <div className="mt-5" id="otica">
          <h3 className="conecta-h3 mb-4">Empresas Vinculadas</h3>
          {this.state.oticaLoad ? (
            <ReactLoading type="bubbles" color="#00518b" height={65} width={65} className="rounded mx-auto d-block" />
          ) : (
            this.state.listaOtica.map((item, index) => {
              return <OticaManagementRow item={item} desativarMultiOticas={this.desativarMultiOticas}></OticaManagementRow>;
            })
          )}
          {(this.context.userSession.papelCategoria === "OTICA" || this.context.userSession.papelCategoria.includes("LAB")) && (
            <div>
              <div className="row">
                <div className="col-12 col-md-8">
                  <Input
                    label="CNPJ"
                    width="100%"
                    pattern="/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/"
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                    type="text"
                    name="novoCnpj"
                    placeholder="Informe o novo cnpj*"
                    value={this.state.novoCnpj}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <CustomButton label="Adicionar Empresa" width="80%" onClick={this.handleSubmit} disabled={!this.state.novoCnpj} />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default OticaManagement;
